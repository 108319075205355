import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { Play, PlayMobile } from '~components/Svg'
import Image from '~components/Image'
import Button from '~components/Button'

const YouTubeVideo = ({ className, content }) => {

	let video = content?.youTubeUrl

	if(!video) return null

	if(video?.includes('?v=')) {
		video = video.split('?v=')[1]
		video = `https://www.youtube.com/embed/${video}`
	} else if(video?.includes('embed/')) {
		video = video.split('embed/')[1]
		video = `https://www.youtube.com/embed/${video}`
	}

	const [play, setPlay] = useState(false)
	const [hover, setHover] = useState()

	const url = play ? `${video}?autoplay=1` : `${video}?autoplay=0`

	const playVideo = () => {
		setPlay(true)
	}

	return (
		<Wrap 
			className={className}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			{video &&
				<Container 				
					onClick={()=> playVideo()}
				>
					<Overlay playing={play}>
						<Circle hover={hover}>
							<PlayIcon />
							<PlayIconMob />
						</Circle>
						<Placeholder image={content.placeholderImage}/>
					</Overlay>
					<IFrame 
						src={url} 
						frameBorder='0' 
						allowFullScreen
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					/>
				</Container>
			}
			<VideoButtonBlock playing={play}>
				<VideoButton play color='green' onClick={()=> playVideo()}>Watch video</VideoButton>
			</VideoButtonBlock>
		</Wrap>
	)
}

const Wrap = styled.div`
`
const Container = styled.div`
	position: relative; 
	padding-bottom: 56.25%;
	height: 0; 
	overflow: hidden; 
	max-width: 100%;
	margin-bottom: var(--l);
	border-radius: 10px;
`
const Overlay = styled.div`
	position: absolute; 
	top: 0; 
	left: 0; 
	right: 0%; 
	bottom: 0;
	display: grid;
	align-content: center;
	justify-content: center;
	z-index: 1;
	opacity: ${props => props.playing ? '0' : '1'};
	transition: opacity 0.2s;
	pointer-events: ${props => props.playing ? 'none' : 'all'};
	cursor: pointer;
`
const Circle = styled.div`
	width: var(--xl);
	height: var(--xl);
	background-color: var(--white);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	position: relative;
	z-index: 1;
	transition: transform 0.2s;
	transform: ${props => props.hover ? 'scale(1.1)' : 'scale(1)'};
	${mobile}{
		width: 52px;
		height: 52px;
	}
`
const PlayIcon = styled(Play)`
	width: 35px;
	height: 39px;
	color: var(--green);
	margin-left: 7px;
	${tablet}{
		display: none;
	}
`
const PlayIconMob = styled(PlayMobile)`
	width: 23px;
	height: 24px;
	color: var(--green);
	margin-left: 6px;
	display: none;
	${tablet}{
		display: block;
	}
`
const Placeholder = styled(Image)`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
`
const IFrame = styled.iframe`
	position: absolute; 
	top: 0; 
	left: 0; 
	width: 100%; 
	height: 100%; 
`
const VideoButtonBlock = styled.div`
	display: flex;
	justify-content: center;
	opacity: ${props => props.playing ? '0' : '1'};
	transition: opacity 0.3s;
	transition-delay: 0.2s;
`
const VideoButton = styled(Button)`
	
`

YouTubeVideo.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default YouTubeVideo