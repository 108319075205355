import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Image from '~components/Image'
import { mobile } from '~styles/global'
import { motion } from 'framer-motion'

const ImageWithShadow = ({ className, color, image }) => {

	const [loadBackground, setLoadBackground] = useState(false)
	const [loadImage, setLoadImage] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			setLoadBackground(true)
		}, 600)
	}, [])

	return (
		<Wrap className={className}>
			<ImageAnimation
				initial={{
					opacity: 0,
					scale: 0.9,
					rotate: 30
				}}
				animate={{
					opacity: 1,
					scale: 1,
					rotate: 0
				}}
				transition={{
					delay: 1.2,
					// ease: [.6, .01, -.05, 0.95],
					type: 'spring',
					stiffness: 100
				}}
			>
				<HeaderImage image={image} loaded={loadImage}/>
			</ImageAnimation>
			<ShadowAnimation>
				<ImageShadow color={color} loaded={loadBackground}/>
			</ShadowAnimation>
		</Wrap>
	)	
}

const Wrap = styled.div`
	position: relative;
`
const ImageAnimation = styled(motion.div)`
	position: relative;
	z-index: 1;
`
const ShadowAnimation = styled(motion.div)`
	
`
const HeaderImage = styled(Image)`
	margin-right: var(--l);
	position: relative;
	z-index: 1;
	border-radius: 10px;
	overflow: hidden;
	/* opacity: ${props => props.loaded ? '1' : '0'}; */
	/* transition: opacity 0.6s, ; */
	${mobile}{
		margin-right: 19px;
	}
`
const ImageShadow = styled.div`
	background-color: ${props => props.color ? `var(--${props.color})` : 'var(--blue)'};
	margin-left: 40px;
	position: absolute;
	transform: translateY(calc(var(--l) * -1));
	border-radius: 10px;
	top: 0;
	right: 0;
	bottom:${props => props.loaded ? '0%' : '100% '};
	left: 0;
	transition: all 0.6s ease;

	${mobile}{
		transform: translateY(-19px);
		margin-left: 19px;
	}
`


ImageWithShadow.propTypes = {
	className: PropTypes.string,
	color: PropTypes.object,
	image: PropTypes.object,
}

export default ImageWithShadow